












@import "~/css/_bootstrapRequired.scss";

.SoleApp {
  width: 100%;
  min-height: 60vw;
  @include media-breakpoint-up(md) {
    min-height: 30vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 30vw;
  }
}
