@media only screen and (min-width: 600px) {
  h3 {
    font-size: 2.25em !important;
  }
  h2 {
    font-size: 2.75em !important;
  }
  h1 {
    font-size: 44px !important;
  }
  a {
    font-size: 1em !important;
  }
}
@media only screen and (max-width: 599px) and (min-width: 340px) {
  h3 {
    font-size: 1.75em !important;
  }
  h2 {
    font-size: 2em !important;
  }
  h1 {
    font-size: 2.25em !important;
  }
  a {
    font-size: 1em !important;
  }
}
@media only screen and (max-width: 340px) {
  h3 {
    font-size: 1.5em !important;
  }
  h2 {
    font-size: 2em !important;
  }
  h1 {
    font-size: 3em !important;
  }
  a {
    font-size: 1em !important;
  }
}