@import "~/css/_bootstrapRequired.scss";

.HomePage__content {
  .nav {
    margin-left: calc(0.1rem + 2%);
    margin-right: calc(0.1rem + 2%);
    @include media-breakpoint-up(md) {
      margin-left: 1.5%;
      margin-right: 1.5%;
    }
    & .nav-item {
      text-align: center;
      min-width: 23%;
      @include media-breakpoint-up(md) {
        min-width: 16%;
      }
      @include media-breakpoint-up(lg) {
        min-width: 10%;
      }
    }
  }
  @import "./homepage/display";
  @import "./homepage/tiles";
}

@import "./product/popover";
