@import "~/css/_bootstrapRequired.scss";
@import "{ .globerRegular } from ~Css/landing/fonts/font-styling";
@import "{ .p-0, .pb-2 } from ~Css/bootstrap/utilities/_spacing.scss";

.popover-tooltip {
  background: $SOLE-black;
  color: $SOLE-white;
  font-weight: bold;
  padding: 1rem;
  z-index: 20;
  display: none;
  min-width: 200px;
  max-width: 300px;
  max-height: 300px;
  .header {
    @extend .globerRegular, .p-0, .pb-2;
    background: black !important;
    color: white !important;
    font-size: 1.1rem;
    text-align: left;
    font-weight: 700;
  }
  .body {
    @extend .globerRegular, .p-0, .pt-2;
    background: black !important;
    color: white !important;
    font-size: 0.9rem;
    text-align: left;
    font-weight: 400;
  }
}

.popover-tooltip[data-show] {
  display: block;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.popover-tooltip[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
}

.popover-tooltip[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

.popover-tooltip[data-popper-placement^="left"] > .arrow {
  right: -4px;
}

.popover-tooltip[data-popper-placement^="right"] > .arrow {
  left: -4px;
}