.Tiles__container {
  overflow: hidden;
  & .btn {
    min-width: 150px;
  }
  & .image-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 88%;
    position: relative;
    & img {
      width: 100%;
      height: auto;
      position: absolute;
    }
  }
  & .content-above-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .Tiles__container .col-md-3 {
    & h1, h2, h3 {
      display: none;
    }
  }
}